.jodit-react-container {
  width: 100%; }

.jodit-container {
  background-color: white !important; }

.jodit-workplace {
  background: white !important; }

.setting-channel .checkbox {
  margin: 0; }

.user img {
  width: 30px;
  height: 30px; }

.setting-group-assign .ticket-setting .check-box-form .radio-tick span .form-control {
  width: 50px !important;
  text-align: center; }

.assign-user-list-content .assign-user-list .ticket-limit span .form-control {
  width: 50px !important;
  margin-right: 5px !important;
  margin-top: -7px;
  height: 30px !important;
  text-align: center; }

.sort-handler {
  display: inline-block;
  width: 10px;
  height: 9px;
  background-image: url("../../../assets/img/icon/arrow-down.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  cursor: pointer; }

.sort-handler.asc {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.nav > li > a:hover,
.nav > li > a:focus {
  color: #414042 !important; }

.ticket-limit-btn {
  margin-left: 5px; }
  .ticket-limit-btn img {
    width: 15px; }

.ticket-limit-apply {
  width: auto !important;
  margin-left: 10px; }

.canned-keyword-dialog {
  width: 1000px !important;
  margin: 2% auto !important; }

.canned-condition-dialog {
  margin: 3% auto !important; }

.canned-repsponse .media-setting .edit-switch {
  margin-left: 10px; }

.upload-box .image-upload {
  margin-top: 20px; }

.upload-box .image-upload .img-item {
  display: inline-block;
  cursor: pointer;
  margin-right: 10px; }

.upload-box .image-upload .img-item .mask {
  display: none;
  position: absolute;
  background: #414042;
  opacity: 0.7;
  height: 50px;
  width: 75px;
  z-index: 5; }

.upload-box .image-upload .img-item img.delete-icon {
  display: none;
  position: absolute;
  width: 11px;
  height: 11px;
  margin-top: 20px;
  margin-left: 33px;
  z-index: 5; }

.upload-box .image-upload .img-item:hover .mask,
.upload-box .image-upload .img-item:hover img.delete-icon {
  display: block; }

.upload-box .image-upload .img-item > img {
  position: relative;
  width: 75px;
  height: 50px;
  border-radius: 0; }

.upload-box .image-upload .add-img {
  display: inline-block;
  position: relative;
  width: 75px;
  height: 50px;
  border: dashed 1px #dcdbdb;
  background-image: url("../../../assets/img/icon/plus-2.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }

.question-list {
  border-radius: 4px;
  border-width: 0px;
  background-color: #e7eef3;
  cursor: move;
  position: relative;
  padding: 15px 15px 5px 15px;
  min-height: 40px;
  align-items: center;
  margin-top: 10px; }
  .question-list p {
    font-weight: bold;
    margin-bottom: 0px; }
  .question-list .move-list {
    position: absolute;
    right: 15px; }
    .question-list .move-list img {
      height: 15px;
      width: 15px;
      margin-left: 15px; }

.question-list:hover {
  background-color: rgba(0, 167, 201, 0.2); }

.question-list.active {
  background-color: rgba(0, 167, 201, 0.2); }

.question-delete-btn {
  cursor: pointer;
  position: absolute;
  bottom: 17px;
  right: 25px; }

.big-radio input[type=radio] {
  transform: scale(1.5); }

.big-checkbox input[type=checkbox] {
  transform: scale(1.5);
  margin-top: 4px; }

.preview-modal .modal-dialog {
  width: 850px !important;
  margin: 1% auto; }
  .preview-modal .modal-dialog .save-option {
    justify-content: space-around !important; }
    .preview-modal .modal-dialog .save-option .submit-button {
      width: auto !important;
      min-width: 120px;
      white-space: pre-line; }
  .preview-modal .modal-dialog .success-content {
    text-align: center;
    white-space: pre-wrap; }

.sub-menu-dropdown {
  padding-left: 15px !important;
  padding-top: 7px !important; }
  .sub-menu-dropdown li a .menu-search {
    display: flex;
    margin-bottom: 0px; }
  .sub-menu-dropdown .topic-menu {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    display: block !important; }

.survey-apply-engagement {
  position: absolute;
  top: 9px;
  right: 130px; }

.survey-engagement-modal .modal-dialog {
  width: 720px !important;
  margin: 1% auto; }

.survey-engagement {
  top: 0px !important; }

.survey-right {
  right: 10px !important; }
  .survey-right select {
    width: 320px; }

.border-left-top {
  width: auto !important; }

.solid-img {
  opacity: 1 !important;
  cursor: default !important; }

.engagement-script-tag {
  position: absolute;
  cursor: pointer;
  right: 120px;
  top: 6px; }
  .engagement-script-tag img {
    width: 16px; }

.engagement-signature {
  position: absolute;
  cursor: pointer;
  right: 70px;
  top: 6px; }
  .engagement-signature img {
    width: 16px; }

.webchat-connect-modal {
  width: 520px !important; }
  .webchat-connect-modal .toggle-script {
    cursor: pointer; }
    .webchat-connect-modal .toggle-script .modal-dialog {
      max-width: none; }
    .webchat-connect-modal .toggle-script img {
      margin-left: 5px;
      width: 9px; }
    .webchat-connect-modal .toggle-script p {
      font-size: 14px; }

.select-facebook-app {
  width: 520px !important; }
  .select-facebook-app .toggle-script {
    cursor: pointer; }
    .select-facebook-app .toggle-script .modal-dialog {
      max-width: none; }
    .select-facebook-app .toggle-script img {
      margin-left: 5px;
      width: 9px; }
    .select-facebook-app .toggle-script p {
      font-size: 14px; }

.engage-setting .social-channel {
  display: flex !important;
  max-width: 400px;
  flex-wrap: wrap; }
  .engage-setting .social-channel .select-social-channel {
    float: left;
    margin: 10px 5px 10px 0 !important;
    width: 70px !important; }

.canned-repsponse .head-title p {
  text-transform: none !important; }

.create-canned-modal .modal-dialog {
  width: 1000px !important; }

.create-canned-modal .form-group {
  margin-bottom: 10px !important; }

.create-canned-modal .available-header {
  display: -webkit-inline-box; }
  .create-canned-modal .available-header img {
    margin-left: 5px; }

.create-canned-modal .modal-body {
  max-height: unset !important; }

.create-canned-modal .conversation-box .replies .reply-textarea {
  height: 200px !important; }

.create-canned-modal .conversation-box .replies .message-user-header {
  display: flex;
  padding: 8px 10px;
  border-bottom: none;
  background-color: #fafbfb;
  border: 1px solid #dcdbdb;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px; }
  .create-canned-modal .conversation-box .replies .message-user-header button {
    border: none;
    display: grid;
    color: black;
    grid-gap: 4px;
    border-radius: 4px;
    align-items: center;
    grid-auto-flow: column;
    background: transparent; }
    .create-canned-modal .conversation-box .replies .message-user-header button:hover, .create-canned-modal .conversation-box .replies .message-user-header button:focus {
      background-color: #f6f6f7; }
    .create-canned-modal .conversation-box .replies .message-user-header button .caret {
      display: none; }
  .create-canned-modal .conversation-box .replies .message-user-header .canned-variable-dropdown {
    width: 260px; }
    .create-canned-modal .conversation-box .replies .message-user-header .canned-variable-dropdown .dropdown-menu {
      border-radius: 8px;
      border: 1px solid #EAEAEA;
      box-shadow: 0px 12px 16px -4px rgba(22, 29, 34, 0.08), 0px 4px 6px -2px rgba(22, 29, 34, 0.03); }
    .create-canned-modal .conversation-box .replies .message-user-header .canned-variable-dropdown li a {
      margin: 0px;
      display: flex;
      padding: 0px 20px;
      align-items: center;
      border-radius: unset; }
      .create-canned-modal .conversation-box .replies .message-user-header .canned-variable-dropdown li a:hover {
        background: #F6F6F7; }
      .create-canned-modal .conversation-box .replies .message-user-header .canned-variable-dropdown li a div {
        padding: 0px 10px;
        word-break: break-word; }
        .create-canned-modal .conversation-box .replies .message-user-header .canned-variable-dropdown li a div h5 {
          margin-bottom: 2px;
          color: #202020; }
        .create-canned-modal .conversation-box .replies .message-user-header .canned-variable-dropdown li a div h6 {
          margin-top: 2px;
          color: #737373; }

.create-canned-modal .conversation-box .replies .message-user {
  border-top: none;
  border-radius: 0px 0px 4px 4px; }
  .create-canned-modal .conversation-box .replies .message-user [data-name^='zr-canned-'] {
    color: #0C71E3; }

.sla-form-new {
  border-radius: 0px !important;
  padding-right: 50px;
  width: 80px !important; }

.sla-form-new-day {
  border-right-color: white !important; }

.sla-form-new-hour {
  border-left-color: white !important;
  border-right-color: white !important; }

.sla-form-new-minute {
  border-left-color: white !important;
  border-right-color: white !important; }

.sla-form-new-second {
  border-left-color: white !important; }

.sla-seperator {
  position: absolute;
  margin-top: 4px;
  font-size: 16px;
  color: #d1dbe3; }

.sla-label {
  position: absolute;
  margin-top: 10px;
  font-size: 10px; }

.sla-label-day {
  margin-left: -35px; }

.sla-label-hour {
  margin-left: -36px; }

.sla-label-minute {
  margin-left: -40px; }

.sla-label-second {
  margin-left: -44px; }

.sla-level-new {
  position: relative; }

.new-form-horizontal {
  margin: -30px 0px;
  display: flex;
  justify-content: center; }

.new-sla-header {
  display: flex;
  justify-content: space-between; }
  .new-sla-header .delete-btn {
    background: none !important;
    border: none !important; }
  .new-sla-header .engagement-btn {
    background: none !important;
    border: 1px solid #B8C1CD;
    color: #3B4859 !important;
    margin-right: 10px; }
  .new-sla-header .new-sla-header-label {
    color: #3B4859;
    font-size: 24px;
    font-weight: bold;
    width: 100%; }

.new-sla-lastupdate {
  font-size: 12px;
  color: #AAAAAA;
  place-self: flex-end;
  width: 100%; }

.sla-accordion-header {
  display: flex;
  place-content: space-between;
  width: 100%;
  font-size: 16px;
  padding-top: 20px; }

.sla-accordion-header-left {
  color: #3B4859; }

.sla-accordion-header-right {
  color: #7C8A9C; }

.sla-accordion-detail {
  display: flex;
  flex-direction: column; }

.sla-business-hour {
  display: flex;
  flex-direction: column; }

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.sla-business-time-input-container {
  display: flex;
  margin-bottom: 10px; }

.sla-business-time-start {
  color: #767676; }

.sla-business-time-end {
  color: #767676; }

.sla-business-time-days-container {
  display: flex; }

.sla-business-time-days {
  height: 48px;
  width: 59px;
  border: 2px solid #074EE8;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 6px;
  cursor: pointer; }

.sla-business-time-days-inactive {
  color: #074EE8;
  background: white; }

.sla-business-time-days-active {
  color: white;
  background: #074EE8; }

.sla-custom-time {
  display: flex; }

.sla-custom-time-switch {
  display: flex;
  align-items: center;
  margin-top: 10px; }

.sla-custom-time-label {
  color: #074EE8;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: center;
  margin-top: 10px; }

.new-sla-switch .slider:before {
  width: 12px;
  height: 12px;
  bottom: 5px;
  left: 5px; }

.new-sla-switch .slider.round {
  background: white; }

.new-sla-switch input:checked + .slider {
  background-color: #074EE8; }

.sla-radio-label .MuiTypography-root {
  font-size: 16px; }

.sla-radio-label .MuiRadio-colorPrimary {
  color: #074EE8 !important; }

.sla-radio-label .MuiSvgIcon-root {
  font-size: 2rem; }

.new-sla-time-input {
  width: 90px;
  height: 48px;
  padding: 16px;
  border: 2px solid #AAAAAA;
  font-size: 16px;
  border-radius: 4px; }

.sla-business-time-separator {
  border-top: 2px solid;
  width: 16px;
  margin-top: 44px;
  height: 1px;
  margin-left: 4px;
  margin-right: 4px; }

.holiday-days-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 60px; }

.holiday-days-label {
  color: #3B4859;
  margin-right: 20px; }

.holiday-days-value {
  color: #7C8A9C; }

.holiday-calendar {
  width: 100%;
  box-shadow: none !important;
  margin-bottom: 40px; }
  .holiday-calendar .rmdp-calendar {
    margin: unset !important;
    width: 500px !important; }
  .holiday-calendar .rmdp-top-class {
    height: 450px; }
  .holiday-calendar .rmdp-header-values {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin: 0px 0px 0px 34px; }
  .holiday-calendar .rmdp-header {
    display: flex !important;
    margin-left: 20px !important;
    flex-direction: column !important; }
  .holiday-calendar .rmdp-day-picker {
    display: flex;
    justify-content: center; }
  .holiday-calendar .rmdp-week-day {
    font-size: 18px !important;
    margin: 6px;
    color: #3B4859 !important; }
  .holiday-calendar .rmdp-day span {
    border-radius: 4% !important;
    padding: 24px !important;
    align-items: center !important; }
  .holiday-calendar .rmdp-day,
  .holiday-calendar .rmdp-week-day {
    height: 56px !important;
    width: 44px !important; }
  .holiday-calendar .rmdp-arrow {
    height: 9px !important;
    width: 9px !important;
    border: solid #333333 !important;
    border-width: 0 2px 2px 0 !important; }
  .holiday-calendar .rmdp-left,
  .holiday-calendar .rmdp-right {
    position: absolute;
    top: 50%;
    right: 10% !important;
    margin-left: 310px !important;
    transform: translateY(-50%); }
  .holiday-calendar .rmdp-arrow-container:hover {
    background-color: white !important;
    box-shadow: none !important; }
  .holiday-calendar .rmdp-border-right {
    border-right: none !important; }
  .holiday-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #3B7CFF; }

.custom-action-container {
  padding-bottom: 200px; }
  .custom-action-container .btn-add-custom-action {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: #3B4859;
    margin: 5px 0;
    font-weight: bold;
    margin-top: 30px; }
    .custom-action-container .btn-add-custom-action img {
      height: 20px;
      margin-right: 5px; }
  .custom-action-container .custom-action-list {
    max-width: 900px; }
    .custom-action-container .custom-action-list .custom-action-item {
      border: 1px solid #D0D0D0;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      margin-top: 10px; }
      .custom-action-container .custom-action-list .custom-action-item.is-error {
        border: 1px solid red; }
      .custom-action-container .custom-action-list .custom-action-item .topic-box {
        background: #3B4859;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4px 10px;
        width: 52px;
        min-width: 52px;
        color: #FFFFFF;
        margin-right: 18px; }
      .custom-action-container .custom-action-list .custom-action-item .header-action {
        display: flex;
        align-items: center; }
        .custom-action-container .custom-action-list .custom-action-item .header-action .left {
          width: 100%; }
        .custom-action-container .custom-action-list .custom-action-item .header-action .right {
          display: flex;
          align-items: center;
          justify-content: right; }
          .custom-action-container .custom-action-list .custom-action-item .header-action .right button {
            background: none;
            border: none;
            color: #3B4859;
            margin-left: 10px;
            display: flex;
            align-items: center; }
            .custom-action-container .custom-action-list .custom-action-item .header-action .right button img {
              height: 14px;
              margin-right: 5px; }
      .custom-action-container .custom-action-list .custom-action-item .condition-box {
        margin-top: 7px;
        display: flex;
        align-items: center;
        color: #3B4859; }
        .custom-action-container .custom-action-list .custom-action-item .condition-box .statement {
          white-space: nowrap;
          overflow: hidden; }
          .custom-action-container .custom-action-list .custom-action-item .condition-box .statement span {
            font-weight: bold;
            color: #000000; }
        .custom-action-container .custom-action-list .custom-action-item .condition-box .action-set {
          font-weight: bold;
          color: #000000;
          margin: 0 10px 0 0; }
        .custom-action-container .custom-action-list .custom-action-item .condition-box .action-to {
          margin: 0 10px 0 0; }
        .custom-action-container .custom-action-list .custom-action-item .condition-box .del-btn {
          background: none;
          border: none; }
      .custom-action-container .custom-action-list .custom-action-item .edited-time {
        display: flex;
        align-items: center;
        justify-content: end;
        color: #BBC1CC;
        font-size: 12px; }
        .custom-action-container .custom-action-list .custom-action-item .edited-time img {
          height: 20px;
          border-radius: 50%;
          margin: 0 5px 0 10px; }
      .custom-action-container .custom-action-list .custom-action-item .dropdown {
        width: 200px;
        min-width: 200px;
        margin-right: 10px; }
        .custom-action-container .custom-action-list .custom-action-item .dropdown .btn.btn-default {
          width: 100%; }
        .custom-action-container .custom-action-list .custom-action-item .dropdown .dropdown-menu {
          width: 100%;
          overflow: scroll; }
      .custom-action-container .custom-action-list .custom-action-item .dropdown-value {
        width: 100%;
        margin-right: 0px; }
      .custom-action-container .custom-action-list .custom-action-item .btn.btn-default {
        min-height: 33px; }
        .custom-action-container .custom-action-list .custom-action-item .btn.btn-default .arrow-img {
          position: absolute;
          right: 10px;
          top: 10px; }
      .custom-action-container .custom-action-list .custom-action-item .bottom-box {
        display: flex;
        justify-content: flex-end; }
        .custom-action-container .custom-action-list .custom-action-item .bottom-box button {
          margin-left: 10px; }
      .custom-action-container .custom-action-list .custom-action-item .operator-box {
        display: flex;
        align-items: center; }
        .custom-action-container .custom-action-list .custom-action-item .operator-box .btn-add {
          display: flex;
          align-items: center;
          border: none;
          background: none;
          color: #3B4859;
          margin: 5px 0;
          font-weight: bold; }
          .custom-action-container .custom-action-list .custom-action-item .operator-box .btn-add img {
            height: 20px;
            margin-right: 5px; }

.dropdown-arrow-img {
  position: absolute;
  right: 10px;
  top: 10px; }
